import React, { FC } from "react";
import PropTypes from "prop-types";

import styled from "styled-components";

const EmergencyCallStyles = styled.div`
  background: #ce0ba5;
  display: flex;
  margin: 0 -16px;
  padding: 8px 16px;

  & a {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
  }

  & p {
    flex: 1 1 auto;
    color: white;
    font-size: 16px;
    margin: 0;
    line-height: 1.2;
  }
  & strong {
    font-weight: 500;
    font-size: 24px;
    color: white;
    width: 20%;
    text-align: center;
    margin-left: 16px;
    line-height: 1.1;
  }
`;

const propTypes = {
  emergencyNumber: PropTypes.string,
};

const defaultProps = {
  emergencyNumber: "000",
};

export type EmergencyCallProps = PropTypes.InferProps<typeof propTypes>;

export const EmergencyCall: FC<EmergencyCallProps> = ({ emergencyNumber }) => (
  <EmergencyCallStyles>
    <a
      className="li-urgent-help"
      aria-label={`Call ${emergencyNumber}`}
      href={`tel:${emergencyNumber}`}
    >
      <p>If you are in immediate danger, or someone you know is:</p>
      <strong>Call {emergencyNumber}</strong>
    </a>
  </EmergencyCallStyles>
);
EmergencyCall.propTypes = propTypes;
EmergencyCall.defaultProps = defaultProps;
