import styled from "styled-components";
import { mq } from "~/styles/responsive";

export const InfoList = styled.ul`
  padding: 16px 0;
  margin: 0;
  margin-left: 18px;

  @media screen and (max-width: 320px) {
    padding: 8px 0;
  }
`;

export const InfoListItem = styled.li`
  padding: 4px 0;

  ${mq({
    fontSize: ["20px", "16px"],
    lineHeight: ["1.4", "1.2"],
  })}

  @media screen and (max-width: 320px) {
    font-size: 14px;
  }
`;
