import React, { ReactElement, FC, useCallback } from "react";
import PropTypes from "prop-types";

import { InterceptorPage } from "../components/Containers";
import { Stage } from "../config";
import {
  InterceptorActionButtons,
  InterceptorActionButton,
} from "../components/Buttons";

export type Props = {
  setStage: (stage: Stage) => void;
  onDismiss: () => void;
};

export const IntroPage: FC<Props> = ({ setStage, onDismiss }): ReactElement => {
  const startLifelineFlow = useCallback(() => {
    setStage("lifeline-1");
  }, [setStage]);

  return (
    <InterceptorPage>
      <h1 id="liDialogTitle">
        We noticed you’ve been looking at some of our pages about suicide
      </h1>
      <p>We wanted to check in and see if you needed some help.</p>
      <InterceptorActionButtons>
        <InterceptorActionButton
          className="li-action-im-ok"
          primary
          onClick={onDismiss}
        >
          I’m okay
        </InterceptorActionButton>
        <InterceptorActionButton
          className="li-action-need-help"
          onClick={startLifelineFlow}
        >
          I need some help&nbsp;
          <i className="fa fa-arrow-right rfa rfa-arrow-right"></i>
        </InterceptorActionButton>
      </InterceptorActionButtons>
    </InterceptorPage>
  );
};

IntroPage.propTypes = {
  setStage: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
};
