import React, { FC, useCallback } from "react";
import PropTypes from "prop-types";

import styled from "styled-components";
import { mq } from "~/styles/responsive";

export const InterceptorActionButtons = styled.div`
  margin-top: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const InterceptorCloseButton = styled.button`
  position: absolute;
  top: -18px;
  right: -18px;
  background: #ffef5e;
  box-sizing: border-box;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  font-size: 42px;
  cursor: pointer;
  transition: background 0.15s;
  border: none;

  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: #f3e24b;
  }

  ${mq({
    width: ["56px", "48px"],
    height: ["56px", "48px"],
    lineHeight: ["56px", "48px"],
    fontSize: ["42px", "32px"],
    top: ["-18px", "-12px"],
    right: ["-18px", "-12px"],
  })}

  @media screen and (max-width: 320px) {
    top: -8px;
    right: -8px;
  }
`;

const actionButtonPropTypes = {
  primary: PropTypes.bool,
};
const actionButtonDefaultProps = {
  primary: false,
};

type ActionButtonProps = PropTypes.InferProps<typeof actionButtonPropTypes>;

export const InterceptorActionButton = styled.button<ActionButtonProps>`
  background: ${(props): string => (props.primary ? "#FFEF5E" : "#0073CF")};
  color: ${(props): string => (props.primary ? "black" : "white")};
  line-height: 1.2;
  position: relative;
  cursor: pointer;
  transition: background 0.15s;
  border: none;
  font-weight: 600;
  text-align: center;
  display: inline-block;
  margin-top: 16px;
  flex: 1 1 auto;
  min-width: 275px;

  & > i {
    position: absolute;
    right: 16px;

    ${mq({
      fontSize: ["16px", "12px"],
      marginTop: ["1px", "2px"],
    })}

    @media screen and (max-width: 320px) {
      display: none;
    }
  }

  &:hover,
  &:focus {
    background: ${(props): string => (props.primary ? "#f3e24b" : "#0066b9")};
    color: ${(props): string => (props.primary ? "black" : "white")};
  }

  @media screen and (max-width: 320px) {
    min-width: 200px;
  }

  @media screen and (min-width: 720px) {
    ${InterceptorActionButtons} &:first-child {
      margin-right: 8px;
    }
    ${InterceptorActionButtons} &:last-child {
      margin-left: 8px;
    }
    ${InterceptorActionButtons} &:first-child:last-child {
      margin-left: 0;
      margin-right: 0;
    }
  }

  ${mq({
    fontSize: ["18px", "16px"],
    padding: ["16px 8px", "16px 2px"],
  })}

  @media screen and (max-width: 320px) {
    padding: 12px 2px;
    margin-top: 8px;
  }
`;
InterceptorActionButton.propTypes = actionButtonPropTypes;
InterceptorActionButton.defaultProps = actionButtonDefaultProps;

const feedbackButtonPropTypes = {
  action: PropTypes.string.isRequired,
  feedback: PropTypes.string.isRequired,
  onFeedback: PropTypes.func.isRequired,
  className: PropTypes.string,
};

type FeedbackButtonProps = {
  feedback: string;
  action: string;
  onFeedback: (action: string) => void;
  className?: string;
};

const InterceptorFeedbackButtonStyles = styled.button`
  display: block;
  background: #0073cf;
  color: white;
  padding: 12px;
  border-radius: 32px;
  margin-top: 16px;
  cursor: pointer;
  transition: background 0.15s;
  border: none;
  font-weight: 600;

  &:hover {
    background: #0066b9;
  }

  @media screen and (max-width: 320px) {
    margin-top: 8px;
  }
`;

export const InterceptorFeedbackButton: FC<FeedbackButtonProps> = ({
  feedback,
  action,
  onFeedback,
  className,
}) => {
  const onClick = useCallback(() => onFeedback(action), [action, onFeedback]);
  return (
    <InterceptorFeedbackButtonStyles className={className} onClick={onClick}>
      {feedback}
    </InterceptorFeedbackButtonStyles>
  );
};
InterceptorFeedbackButton.propTypes = feedbackButtonPropTypes;

export const InterceptorFeedbackButtons = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;
