export default [
  "/articles/what-to-do-if-you-are-having-thoughts-about-wanting-to-die",
  "/mental-health-issues/anxiety",
  "/articles/what-to-do-if-youre-thinking-about-how-to-commit-suicide",
  "/articles/self-help-for-depression",
  "/articles/7-facts-about-depression",
  "/articles/support-services-for-depression-and-anxiety",
  "/articles/what-is-depression",
  "/articles/what-can-you-do-if-nothing-is-working",
  "/articles/treatments-for-depression",
  "/articles/10-things-to-do-if-youre-feeling-lonely",
  "/mental-health-issues/professional-help",
  "/tools-and-apps/calm-harm",
  "/articles/how-can-i-stop-feeling-lonely",
  "/articles/help-services-for-self-harm",
];
