import React, { ReactNode, ReactElement, FC } from "react";
import PropTypes from "prop-types";

import { InterceptorOverlay, InterceptorFrame } from "../components/Containers";
import { InterceptorCloseButton } from "../components/Buttons";

export type Props = {
  show: boolean;
  page: ReactNode;
  onClose: () => void;
};

export const DialogFrame: FC<Props> = ({
  show,
  page,
  onClose,
}): ReactElement => {
  return (
    <InterceptorOverlay show={show}>
      <InterceptorFrame
        id="liDialog"
        role="dialog"
        aria-live="polite" // TODO: should this be 'assertive' ?
        aria-labelledby="liDialogTitle"
      >
        {page}
        <InterceptorCloseButton aria-label="Close dialog" onClick={onClose}>
          &times;
        </InterceptorCloseButton>
      </InterceptorFrame>
    </InterceptorOverlay>
  );
};

DialogFrame.propTypes = {
  show: PropTypes.bool.isRequired,
  page: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
};
