import React, { ReactElement, FC, useCallback } from "react";
import PropTypes from "prop-types";

import { InterceptorPage } from "../components/Containers";
import { EmergencyCall } from "../components/EmergencyCall";
import {
  InterceptorFeedbackButtons,
  InterceptorFeedbackButton,
} from "../components/Buttons";
import { FeedbackOption, feedbackOptionShape } from "../config";

export type Props = {
  onClose: () => void;
  openNextPage?: () => void;
  title: string;
  textPrompt?: string;
  options: FeedbackOption[];
};

export const LifelineSurveyPage: FC<Props> = ({
  onClose,
  openNextPage,
  title,
  textPrompt = null,
  options,
}): ReactElement => {
  const handleFeedback = useCallback(
    (action) => {
      switch (action) {
        case "feedback":
          onClose();
          break;
        case "next-page":
          if (openNextPage) {
            openNextPage();
          } else {
            onClose();
          }
          break;
      }
    },
    [onClose, openNextPage]
  );

  return (
    <InterceptorPage>
      <h1 id="liDialogTitle">{title}</h1>
      <EmergencyCall />
      {textPrompt && <p>{textPrompt}</p>}
      <InterceptorFeedbackButtons>
        {options.map(({ feedback, action }) => (
          <InterceptorFeedbackButton
            className="li-feedback"
            key={feedback}
            onFeedback={handleFeedback}
            feedback={feedback}
            action={action}
          />
        ))}
      </InterceptorFeedbackButtons>
    </InterceptorPage>
  );
};

LifelineSurveyPage.propTypes = {
  onClose: PropTypes.func.isRequired,
  openNextPage: PropTypes.func,
  title: PropTypes.string.isRequired,
  textPrompt: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape(feedbackOptionShape).isRequired)
    .isRequired,
};
