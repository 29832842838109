import React, { ReactElement, FC, useCallback } from "react";
import PropTypes from "prop-types";

import { InterceptorPage } from "../components/Containers";
import { Stage } from "../config";
import {
  InterceptorActionButtons,
  InterceptorActionButton,
} from "../components/Buttons";
import { EmergencyCall } from "../components/EmergencyCall";

export type Props = {
  setStage: (stage: Stage) => void;
  chatUrl: string;
};

export const LifelinePage1: FC<Props> = ({
  setStage,
  chatUrl,
}): ReactElement => {
  const getLifelineInfo = useCallback(() => {
    setStage("lifeline-2");
  }, [setStage]);

  const callNow = useCallback(() => {
    setStage("lifeline-survey-1");
  }, [setStage]);

  return (
    <InterceptorPage>
      <h1 id="liDialogTitle">You might want to chat with Lifeline</h1>
      <EmergencyCall />
      <p>
        Lifeline has an online chat service where you can speak confidentially
        with a Lifeline Online Crisis Supporter.
      </p>
      <span>
        <strong>Note:</strong>&nbsp;We won’t share what you’ve been looking at
        on ReachOut with the Lifeline Online Crisis Supporter and they won’t
        know we’ve sent you.
      </span>
      <InterceptorActionButtons>
        <InterceptorActionButton
          className="li-action-learn-more"
          primary
          onClick={getLifelineInfo}
        >
          Learn more&nbsp;
          <i className="fa fa-arrow-right rfa rfa-arrow-right"></i>
        </InterceptorActionButton>
        <InterceptorActionButton
          as="a"
          className="li-action-chat"
          onClick={callNow}
          href={chatUrl}
          target="_blank"
          rel="noopener noreferer"
        >
          Chat with Lifeline&nbsp;
          <i className="fa fa-arrow-right rfa rfa-arrow-right"></i>
        </InterceptorActionButton>
      </InterceptorActionButtons>
    </InterceptorPage>
  );
};

LifelinePage1.propTypes = {
  setStage: PropTypes.func.isRequired,
  chatUrl: PropTypes.string.isRequired,
};
