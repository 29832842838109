import PropTypes from "prop-types";

// ----------------------
// Shared types
// ----------------------

export type Stage =
  | "intro"
  | "dismissed"
  | "lifeline-1"
  | "lifeline-2"
  | "lifeline-survey-1"
  | "lifeline-survey-2"
  | "more-options";

export const feedbackOptionShape = {
  action: PropTypes.oneOf(["feedback", "next-page"]).isRequired,
  feedback: PropTypes.string.isRequired,
};

export type FeedbackOption = PropTypes.InferProps<typeof feedbackOptionShape>;

export const externalOptionShape = {
  intro: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export type ExternalOption = PropTypes.InferProps<typeof externalOptionShape>;

// ----------------------
// Dialog popup config
// ----------------------

export const delayBeforeShowing =
  parseInt(process.env.LL_SECONDS_BEFORE_SHOWING || "15", 10) * 1000;

// ----------------------
// Support hours config
// ----------------------

// default to starting at 6pm
export const supportStartHour = parseFloat(
  process.env.LL_SUPPORT_START || "18"
);
// default to running for 6 hours
const durationHours = parseFloat(process.env.LL_SUPPORT_DURATION || "6");
export const supportHoursDuration = durationHours * 3600 * 1000; // hours to millis

// ----------------------
// Risk detector config
// ----------------------

export const visitsKey = "hwc-li-visits";
export const lastShownKey = "hwc-li-shown-at";

// list of page paths that count as hits
export { default as targetPages } from "./targetUrls";

// show the popup on the 2nd page visit
export const interceptThreshold = parseInt(
  process.env.LL_ARTICLE_THRESHOLD_COUNT || "2"
);

// time window for page visits to be counted towards threshold
const timeWindowHours = parseFloat(
  process.env.LL_ARTICLE_THRESHOLD_HOURS || "24"
);
export const relevantTimeWindow = timeWindowHours * 60 * 60 * 1000; // hours to millis

// only show the popup once every
const LastSeenWindowHours = parseFloat(
  process.env.LL_LAST_SEEN_WINDOW_HOURS || "12"
);
export const lastSeenWindow = LastSeenWindowHours * 60 * 60 * 1000; // hours to millis

// ----------------------
// Feedback options config
// ----------------------

export const feedbackOptionsInitial: FeedbackOption[] = [
  { feedback: "I found it helpful", action: "feedback" },
  { feedback: "I didn't find it helpful", action: "next-page" },
];

export const feedbackOptionsReasons: FeedbackOption[] = [
  { feedback: "The support wasn't helpful", action: "next-page" },
  { feedback: "I was waiting too long", action: "next-page" },
  { feedback: "I decided not to connect", action: "next-page" },
  { feedback: "I got disconnected", action: "next-page" },
  { feedback: "Other", action: "next-page" },
];

export const moreOptions: ExternalOption[] = [
  {
    intro: "Hop on to the ReachOut Forums to connect with other young people.",
    label: "Join the community",
    link: "https://au.reachout.com/forums",
  },
  {
    intro: "Read about other professional help options.",
    label: "Professional help",
    link: "https://au.reachout.com/mental-health-issues/professional-help",
  },
];
