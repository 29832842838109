import React, { Component, ReactNode } from "react";

import { DialogFrame } from "./pages/DialogFrame";
import { IntroPage } from "./pages/IntroPage";
import {
  Stage,
  delayBeforeShowing,
  feedbackOptionsInitial,
  feedbackOptionsReasons,
} from "./config";
import { LifelinePage1 } from "./pages/LifelinePage1";
import { DismissedPage } from "./pages/DismissedPage";
import { LifelinePage2 } from "./pages/LifelinePage2";
import { LifelineSurveyPage } from "./pages/LifelineSurveyPage";
import { RiskDetector } from "./services/RiskDetector";
import { MoreOptionsPage } from "./pages/MoreOptionsPage";

export type Props = {
  show?: boolean;
  initialPage?: Stage;
};

type State = {
  show: boolean;
  stage: Stage;
  chatUrl?: string;
};

// Generates a random 12-digit id like: 05jc19mnthws
const generateToken = (): string =>
  (Math.random() * 10000)
    .toString(36)
    .replace(".", "")
    .padStart(12, "0")
    .slice(0, 12);

export default class LifelineInterceptor extends Component<Props, State> {
  private detector: RiskDetector;

  constructor(props: Props) {
    super(props);

    this.detector = new RiskDetector();
    const userToken = generateToken();
    this.state = {
      show: Boolean(props.show),
      stage: (props.initialPage || "intro") as Stage,
      chatUrl: `${process.env.LIFELINE_CHAT_URL}?token=${userToken}`,
    };

    // no need to run assessment if the show prop was set
    if (!props.show) {
      this.detector.runAssessment().then((shouldShow) => {
        if (!shouldShow) return;

        window.heap.track("lifeline_chat_experiment_activated", {
          // eslint-disable-next-line @typescript-eslint/camelcase
          lifeline_chat_experiment_token: userToken,
        });

        // if assessment returned show, then add organic delay before showing popup
        setTimeout((): void => {
          // if another popup in a different tab beat us to it while we delayed, don't show
          if (this.detector.userSawRecently) return;

          this.setState({ show: true });
          this.detector.storeLastShownTime();
          window.heap.track("lifeline_chat_experiment_triggered", {
            // eslint-disable-next-line @typescript-eslint/camelcase
            lifeline_chat_experiment_token: userToken,
          });
        }, delayBeforeShowing);
      });
    }
  }

  setStage = (stage: Stage): void => {
    this.setState({ stage });
  };

  dismiss = (): void => {
    this.setStage("dismissed");
  };

  closeFrame = (): void => {
    if (this.state.stage === "intro") {
      this.dismiss(); // treat close and "I'm okay" the same
    } else if (["lifeline-1", "lifeline-2"].includes(this.state.stage)) {
      this.setStage("more-options"); // if you said you wanted help, but don't visit LL -> show more options
    } else {
      this.close(); // if you're past the intro page, just close out of the flow
    }
  };

  close = (): void => {
    this.setState({ show: false });
  };

  buildPageComponent(stage: Stage): ReactNode {
    switch (stage) {
      case "dismissed":
        return <DismissedPage onClose={this.close} />;
      case "lifeline-1":
        return (
          <LifelinePage1
            setStage={this.setStage}
            chatUrl={this.state.chatUrl}
          />
        );
      case "lifeline-2":
        return (
          <LifelinePage2
            setStage={this.setStage}
            chatUrl={this.state.chatUrl}
          />
        );
      case "lifeline-survey-1":
        return (
          <LifelineSurveyPage
            openNextPage={(): void => this.setStage("lifeline-survey-2")}
            onClose={this.close}
            title="How’d it go?"
            textPrompt="Let us know how your session with Lifeline went."
            options={feedbackOptionsInitial}
          />
        );
      case "lifeline-survey-2":
        return (
          <LifelineSurveyPage
            openNextPage={(): void => this.setStage("more-options")}
            onClose={this.close}
            title="Tell us why"
            options={feedbackOptionsReasons}
          />
        );
      case "more-options":
        return <MoreOptionsPage onClose={this.close} />;
      case "intro":
        return <IntroPage setStage={this.setStage} onDismiss={this.dismiss} />;
    }
  }

  render(): ReactNode {
    const { show, stage } = this.state;

    const page = this.buildPageComponent(stage);

    return <DialogFrame show={show} page={page} onClose={this.closeFrame} />;
  }
}
