/* eslint-disable @typescript-eslint/explicit-function-return-type */

import styled from "styled-components";
import { mq } from "~/styles/responsive";

type OverlayProps = {
  show: boolean;
};

export const InterceptorOverlay = styled.div<OverlayProps>`
  position: fixed;
  z-index: 1150; /* beat the header top bar */
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow-x: hidden;
  overflow-y: auto;

  background: rgba(0, 0, 0, 0.7);

  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;

  display: ${(props) => (props.show ? "flex" : "none")};
`;

export const InterceptorFrame = styled.div`
  background: white;
  padding: 32px 16px;
  max-height: 700px;

  ${mq({
    position: ["fixed", null, null, "relative"],
    height: ["auto", null, null, "100%"],
    width: ["auto", null, null, "650px"],
    margin: ["32px", "16px", null, "5% auto"],
    top: [0, null, null, "unset"],
    bottom: [0, null, null, "unset"],
    left: [0, null, null, "unset"],
    right: [0, null, null, "unset"],
  })}

  @media screen and (max-width: 320px) {
    padding: 16px;
  }
`;

export const InterceptorPage = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  & > h1 {
    margin-right: 16px; /* avoid the close button overlapping the header */
    margin-bottom: 12px;
    font-weight: bold;
    color: black;

    ${mq({
      fontSize: ["32px", "30px"],
      lineHeight: ["1.2", "1.1"],
    })}

    @media screen and (max-width: 320px) {
      font-size: 24px;
    }
  }

  & > p {
    margin: 16px 0 0 0;
    font-size: 20px;

    ${mq({
      fontSize: ["20px", "16px"],
      lineHeight: ["1.5", "1.3"],
    })}

    @media screen and (max-width: 320px) {
      font-size: 14px;
    }
  }

  & > span {
    display: block;
    font-size: 16px;

    ${mq({
      fontSize: ["16px", "14px"],
      lineHeight: ["1.4", "1.3"],
      margin: ["16px 0", "8px 0"],
    })}
  }
`;
