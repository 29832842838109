import { isInSupportHours } from "./chatSupport";
import {
  targetPages,
  visitsKey,
  relevantTimeWindow,
  lastShownKey,
  interceptThreshold,
  lastSeenWindow,
} from "../config";
import { isUserInAustralia } from "./locationService";

const getCurrentTimestamp = (): string => String(new Date().valueOf());

export class RiskDetector {
  visits: string[] = [];
  constructor() {
    this.visits = this.getVisitsWithinWindow();
  }

  get recentVisits(): number {
    return this.visits.length;
  }

  get lastSeenAt(): number {
    const lastDismiss = localStorage.getItem(lastShownKey);
    if (lastDismiss) return parseInt(lastDismiss);
    return -1;
  }

  get userSawRecently(): boolean {
    const recentPopupThreshold = new Date().valueOf() - lastSeenWindow;
    return this.lastSeenAt >= recentPopupThreshold;
  }

  async shouldShowInterceptor(): Promise<boolean> {
    // if user has recently seen this popup, don't show it again
    if (this.userSawRecently) return false;

    const now = new Date();
    // only allow the popup to show if it's within the chat service supported hours
    if (isInSupportHours(now)) {
      const australianUser = await isUserInAustralia();
      if (!australianUser) return false;

      // assuming everything else is true, only show the popup if the user has read
      // enough pages of relevant topics in the last 24 hours
      return this.recentVisits >= interceptThreshold;
    }

    return false;
  }

  addPageVisit(timestamp: string): void {
    this.visits.push(timestamp);
    localStorage.setItem(visitsKey, JSON.stringify(this.visits));
  }

  storeLastShownTime(): void {
    localStorage.setItem(lastShownKey, getCurrentTimestamp());
  }

  getVisitsWithinWindow(): string[] {
    const visits = JSON.parse(localStorage.getItem(visitsKey) || "[]");
    const startOfWindow = new Date().valueOf() - relevantTimeWindow;
    const isVisitInWindow = (visit: string): boolean =>
      parseInt(visit) >= startOfWindow;

    return visits.filter(isVisitInWindow);
  }

  isCurrentPageRelevant(): boolean {
    return targetPages.includes(window.location.pathname.toLowerCase());
  }

  async runAssessment(): Promise<boolean> {
    if (this.isCurrentPageRelevant()) {
      this.addPageVisit(getCurrentTimestamp());
    }

    return this.shouldShowInterceptor();
  }
}
