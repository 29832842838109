import React, { FC } from "react";
import PropTypes from "prop-types";

import styled from "styled-components";
import { mq } from "~/styles/responsive";

const StyledPictureWrapper = styled.picture`
  flex: 1 1 auto;
  width: auto;
  margin: 16px;
  box-sizing: border-box;
  overflow: hidden;
`;

const InterceptorImageComponent: FC<ImageProps> = ({
  className,
  desktopSrc,
  mobileSrc,
  alt,
}) => (
  <StyledPictureWrapper>
    <source
      className={className}
      media="(max-width: 599px)"
      srcSet={mobileSrc}
    />
    <source
      className={className}
      media="(min-width: 600px)"
      srcSet={desktopSrc}
    />
    {/* IE fallback */}
    <img className={className} src={desktopSrc} alt={alt} />
  </StyledPictureWrapper>
);
const imagePropShape = {
  className: PropTypes.string,
  desktopSrc: PropTypes.string.isRequired,
  mobileSrc: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
};
InterceptorImageComponent.propTypes = imagePropShape;
type ImageProps = PropTypes.InferProps<typeof imagePropShape>;

export const InterceptorImage = styled(InterceptorImageComponent)`
  object-position: center top;

  ${mq({
    objectFit: ["contain", "cover", "cover"],
  })}
`;
