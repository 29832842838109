import React, { ReactElement, FC } from "react";
import PropTypes from "prop-types";

import { InterceptorPage } from "../components/Containers";
import {
  InterceptorActionButtons,
  InterceptorActionButton,
} from "../components/Buttons";
import { InterceptorImage } from "../components/Image";

export type Props = {
  onClose: () => void;
};

export const DismissedPage: FC<Props> = ({ onClose }): ReactElement => {
  return (
    <InterceptorPage>
      <h1 id="liDialogTitle">We’ll leave you to it for now</h1>
      <p>
        If you need help later, take a look at <strong>Urgent Help</strong>
      </p>
      <InterceptorImage
        mobileSrc="https://ro-assets-public.s3-ap-southeast-2.amazonaws.com/ll-urgent-help-mobile.png"
        desktopSrc="https://ro-assets-public.s3-ap-southeast-2.amazonaws.com/ll-urgent-help-desktop.png"
        alt="Links to Urgent Help are found in the header on all ReachOut pages"
      />
      <InterceptorActionButtons>
        <InterceptorActionButton className="li-action-close" onClick={onClose}>
          Close
        </InterceptorActionButton>
      </InterceptorActionButtons>
    </InterceptorPage>
  );
};

DismissedPage.propTypes = {
  onClose: PropTypes.func.isRequired,
};
