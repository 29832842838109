import { parse } from "date-fns";
import { supportStartHour, supportHoursDuration } from "../config";

// NOTE: this method only works because the support hours don't overlap multiple days
// FIXME: if the support hours get extended past midnight
const startHourOfCurrentDay = (now: Date): Date => {
  const currentDateStr = [now.getFullYear(), now.getMonth() + 1, now.getDate()]
    .map((n) => String(n).padStart(2, "0"))
    .join("-");

  const startTimeStr = [supportStartHour, 0]
    .map((n) => String(n).padStart(2, "0"))
    .join(":");

  const tzStr = "+1000"; // TODO: confirm if support hours change in AEST/AEDT
  const dateStr = `${currentDateStr} ${startTimeStr} ${tzStr}`;
  return parse(dateStr, "yyyy-MM-dd HH:mm XX", new Date());
};

export const isInSupportHours = (now: Date): boolean => {
  // for local development, set this env var to make the whole day valid support hours
  const ignoreSupportHoursCheck = process.env.LL_IGNORE_TIME_CHECK === "true";
  if (ignoreSupportHoursCheck) return true;

  // this gets a min and max time in the current timezone where the support team is active
  const minTime = startHourOfCurrentDay(now);
  const maxTime = new Date(minTime.getTime() + supportHoursDuration);

  // console.log('Min time:', minTime, 'Max time:', maxTime, 'Now:', now, '=>', now >= minTime && now <= maxTime)

  // use inclusive boundaries since it's better to show it than not
  return now >= minTime && now <= maxTime;
};
