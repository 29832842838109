import loadScript from "load-script2";

export const getUserCountry = (geo: GeoIp2): Promise<string> =>
  new Promise((res, rej): void => {
    geo.country(
      // eslint-disable-next-line @typescript-eslint/camelcase
      (response) => res(response.country.iso_code.toLowerCase()),
      rej
    );
  });

export const isUserInAustralia = async (): Promise<boolean> => {
  try {
    // re-use existing if possible, otherwise load and wait
    if (!window.geoip2) {
      console.warn("GeoIp2 not found. Loading...");
      await loadScript("//geoip-js.com/js/apis/geoip2/v2.1/geoip2.js");
    }
    const country = await getUserCountry(window.geoip2);
    return country === "au";
  } catch (e) {
    // console.warn("Failed to validate user location for support. Assuming Australia");
    return true;
  }
};
