import React, { ReactElement, FC, useCallback } from "react";
import PropTypes from "prop-types";

import { InterceptorPage } from "../components/Containers";
import {
  InterceptorActionButtons,
  InterceptorActionButton,
} from "../components/Buttons";
import { EmergencyCall } from "../components/EmergencyCall";
import { Stage } from "../config";
import { InfoList, InfoListItem } from "../components/InfoList";

export type Props = {
  setStage: (stage: Stage) => void;
  chatUrl: string;
};

export const LifelinePage2: FC<Props> = ({
  setStage,
  chatUrl,
}): ReactElement => {
  const callNow = useCallback(() => {
    setStage("lifeline-survey-1");
  }, [setStage]);

  return (
    <InterceptorPage>
      <h1 id="liDialogTitle">Lifeline Crisis Support Chat</h1>
      <EmergencyCall />
      <InfoList>
        <InfoListItem>
          You can choose to remain anonymous or provide your name and email
          address
        </InfoListItem>
        <InfoListItem>
          This is a private, real-time chat with a trained crisis supporter
        </InfoListItem>
        <InfoListItem>
          Your crisis supporter will listen, provide support and help you
          understand the options available
        </InfoListItem>
      </InfoList>
      <InterceptorActionButtons>
        <InterceptorActionButton
          as="a"
          className="li-action-chat"
          onClick={callNow}
          href={chatUrl}
          target="_blank"
          rel="noopener noreferer"
        >
          Chat with Lifeline&nbsp;
          <i className="fa fa-arrow-right rfa rfa-arrow-right"></i>
        </InterceptorActionButton>
      </InterceptorActionButtons>
    </InterceptorPage>
  );
};

LifelinePage2.propTypes = {
  setStage: PropTypes.func.isRequired,
  chatUrl: PropTypes.string.isRequired,
};
