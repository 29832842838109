import React, { FC, Fragment } from "react";
import PropTypes from "prop-types";

import { InterceptorPage } from "../components/Containers";
import {
  InterceptorActionButtons,
  InterceptorActionButton,
} from "../components/Buttons";
import { EmergencyCall } from "../components/EmergencyCall";
import { moreOptions } from "../config";
import styled from "styled-components";
export type Props = {
  onClose: () => void;
};

const OptionsList = styled.div`
  display: flex;
  flex-direction: column;

  > p {
    font-size: 16px;
    line-height: 1.2;
    margin: 8px 0;
  }
  > a {
    margin-top: 0;
    flex: 1 1 auto;
  }

  @media screen and (max-width: 320px) {
    > p {
      font-size: 14px;
    }
  }
`;

export const MoreOptionsPage: FC<Props> = ({ onClose }) => {
  return (
    <InterceptorPage>
      <h1 id="liDialogTitle">Here are some other options that might help</h1>
      <EmergencyCall />
      <OptionsList>
        {moreOptions.map((o) => (
          <Fragment key={o.label}>
            <p>{o.intro}</p>
            <InterceptorActionButton
              primary
              as="a"
              className="li-action-other-option"
              href={o.link}
              target="_blank"
              rel="noopener noreferer"
            >
              {o.label}&nbsp;
              <i className="fa fa-arrow-right rfa rfa-arrow-right"></i>
            </InterceptorActionButton>
          </Fragment>
        ))}
      </OptionsList>
      <InterceptorActionButtons>
        <InterceptorActionButton className="li-action-close" onClick={onClose}>
          Close
        </InterceptorActionButton>
      </InterceptorActionButtons>
    </InterceptorPage>
  );
};

MoreOptionsPage.propTypes = {
  onClose: PropTypes.func.isRequired,
};
