import React from "react";
import ReactDOM from "react-dom";
import LifelineInterceptor from "../LifelineInterceptor";

const tagName = "hwc-lifeline";
const attachComponent = (): void => {
  const targets = document.getElementsByTagName(tagName);

  Array.from(targets).forEach((elem) => {
    ReactDOM.render(<LifelineInterceptor />, elem);
  });
};

// include Axe for accessibility when running locally
// NOTE: all deployed builds use 'production' mode
if (process.env.NODE_ENV !== "production") {
  import("react-axe").then(({ default: axe }) => {
    axe(React, ReactDOM, 1000);
    console.log("Running with React Axe for accessibility checking...");
    attachComponent();
  });
} else {
  attachComponent();
}
